<template>
	<div>
		<a-tabs default-active-key="1">
			<a-tab-pane key="1" tab="Finanzas" v-if="allowShowGraphic">
				<div>
					<div class="row">
						<div class="col-md-2">
							<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Año</h2>
							<a-select v-model="year" :style="{ width: '100%' }" @change="initModule">
								<a-select-option value="2023"> 2023 </a-select-option>
								<a-select-option value="2024"> 2024 </a-select-option>
							</a-select>
							<!-- <button @click="initModule">initModule()</button> -->
						</div>
						<div class="col-md-2">
							<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Semestre</h2>
							<a-select v-model="semester" :style="{ width: '100%' }" @change="initModule">
								<a-select-option value="1"> Primer semestre </a-select-option>
								<a-select-option value="2"> Segundo semestre </a-select-option>
							</a-select>
						</div>
						<div class="col-md-2">
							<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Segmentación</h2>
							<a-select v-model="segment" :style="{ width: '100%' }" @change="initModule">
								<a-select-option value="q"> Quincenal </a-select-option>
								<a-select-option value="m"> Mensual </a-select-option>
							</a-select>
						</div>
						<div class="col-md-2">
							<h2 class="font-size-18 font-weight-bold mb-1 text-dark">Ubicación</h2>
							<a-select v-model="branch_office_id" :style="{ width: '100%' }" @change="initModule">
								<a-select-option value=""> Todas </a-select-option>
								<a-select-option value="1"> Matriz </a-select-option>
								<a-select-option value="2"> Centro </a-select-option>
							</a-select>
						</div>
						<div class="col-md-3">
							<div class="text-primary font-weight-bold font-size-24 text-right">
								Meta semestral ${{ dashboardReport.semestralGoal / 1000000 }}K
								<div class="font-size-18" style="color: #f5222e">~ ${{ numeral(dashboardReport.average).format('0,0') }}</div>
							</div>
						</div>
					</div>

					<div class="mb-3 pt10" v-if="dashboardReport.granTotalGroup > 0">
						<vue-c3 :handler="bar" class="height-300"></vue-c3>
						<a-progress
							type="line"
							:showInfo="false"
							:strokeWidth="30"
							status="active"
							:strokeColor="{
								'0%': '#f8d7da',
								'50%': '#fff3cd',
								'100%': '#d1e7dd',
							}"
							:percent="dashboardReport.percent"
						/>
					</div>
					<div class="d-flex text-gray-5 justify-content-between font-size-14">
						<span class="text-uppercase">{{ dashboardReport.percent }}% --> {{ numeral(dashboardReport.granTotalGroup).format('$0,0.00') }}</span>
					</div>
					<hr class="hrText" data-content="♦" />
				</div>
				<div class="row">
					<div class="col-md-4 text-center" v-if="branch_office_id == 1">
						<div class="card bg-purple">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Otros ingresos</div>
									<div class="font-size-18">{{ numeral(dashboardReport.panel.in).format('$0,0.00') }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center" :class="branch_office_id == 1 ? 'col-md-8' : 'col-md-12'">
						<div class="card bgSoftGreen">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Ingresos por ventas en banco</div>
									<div class="font-size-18">{{ numeral(totalIngresos).format('$0,0.00') }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-4 text-center" v-if="branch_office_id == 1 && allowShowCash">
						<div class="card bg-purple border-0">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Limpio</div>
									<div class="font-size-14">
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ingresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport?.panel?.in).format('$0,0.00') }}
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Egresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.panel.out).format('$0,0.00') }}
											</div>
										</div>
										<div class="row p0">
											<div class="col-md-12">
												<hr />
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ganancia</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.panel.in - dashboardReport.panel.out).format('$0,0.00') }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 text-center" v-if="allowShowCash">
						<div class="card bgGreen border-0">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Productos</div>
									<div class="font-size-14">
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ingresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.pacific.in).format('$0,0.00') }}
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Egresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.pacific.out).format('$0,0.00') }}
											</div>
										</div>
										<div class="row p0">
											<div class="col-md-12">
												<hr />
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ganancia</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.pacific.in - dashboardReport.pacific.out).format('$0,0.00') }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 text-center" v-if="allowShowCash">
						<div class="card bgRls border-0">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Mano de obra</div>
									<div class="font-size-14">
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ingresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.rls.in).format('$0,0.00') }}
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Egresos</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.rls.out).format('$0,0.00') }}
											</div>
										</div>
										<div class="row p0">
											<div class="col-md-12">
												<hr />
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Ganancia</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.rls.in - dashboardReport.rls.out).format('$0,0.00') }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 text-center" v-if="false">
						<!-- <div class="card bgSoftGreen border-0 pointer" @click="getLoanPurchaseOrders"> -->
						<div class="card bgRls border-0">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Deuda</div>
									<div class="font-size-14">
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Por pagar</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.loans.amount).format('$0,0.00') }}
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div># de pagos pendientes</div>
											</div>
											<div class="col-md-5 text-right">
												{{ dashboardReport.loans.paymentNumber }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 text-center">
						<div class="card bgGreen border-0">
							<div class="card-body">
								<div class="font-weight-bold">
									<div class="font-size-21">Ahorro</div>
									<div class="font-size-14">
										<div class="row">
											<div class="col-md-7 text-left">
												<div>S2R</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.savings.s2r).format('$0,0.00') }}
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>RLS</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.savings.rls).format('$0,0.00') }}
											</div>
										</div>
										<div class="row p0">
											<div class="col-md-12">
												<hr />
											</div>
										</div>
										<div class="row">
											<div class="col-md-7 text-left">
												<div>Total</div>
											</div>
											<div class="col-md-5 text-right">
												{{ numeral(dashboardReport.savings.s2r + dashboardReport.savings.rls).format('$0,0.00') }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="Asistencias" force-render v-if="[1, 11, 27, 31, 41].includes(user.user_id)">
				<attendanceRecord />
			</a-tab-pane>
			<a-tab-pane key="3" tab="Productividad" force-render>
				<button @click="getProductivity">getProductivity()</button>
			</a-tab-pane>
		</a-tabs>
		<a-modal :visible="loanPurchaseOrders.visible" title="Resumen de ODC por Préstamo Bancario" @cancel="onCloseModal" :closable="true" width="90%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal"> Cerrar </a-button>
			</template>
			<div>
				<a-table
					:columns="columns"
					:dataSource="loanPurchaseOrders.elements"
					:scroll="{ x: 1300 }"
					:rowClassName="
						(_record) => {
							if (_record.status == 1 || _record.status == 1.1) {
								return 'bgYellow'
							}
						}
					"
				>
					<div slot="created_at" slot-scope="record">{{ moment(record.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
					<div slot="payment_date" slot-scope="record">
						{{ record.payment_date ? `${moment(record.payment_date).format('DD-MM-YYYY HH:mm')} hrs.` : '-' }}
					</div>
					<div slot="business_name" slot-scope="record" class="text-left">
						{{ record.business_name }}
					</div>
					<div slot="elements" slot-scope="record" class="text-left">
						<div v-for="(e, index) in record.elements" :key="index">{{ `${e.quantity}x ${e.description}` }}</div>
					</div>
					<div slot="expense_type" slot-scope="record" class="text-left">
						<div v-if="record.expense_type">
							{{ getcfdiUse(record.expense_type) }}
						</div>
					</div>
					<div slot="payment_method_data" slot-scope="record" class="text-left">
						{{ record.payment_method_data ? getPaymentMethod(record.payment_method_data) : '' }}
					</div>
					<div slot="subtotal" slot-scope="record" class="text-right">
						{{ numeral(getSubtotal(record.id)).format('$0,0.00') }}
					</div>
					<div slot="general_discount" slot-scope="record" class="text-right">
						{{ numeral(record.general_discount).format('$0,0.00') }}
					</div>
					<div slot="iva" slot-scope="record" class="text-right">
						{{ numeral(getIVA(record.id)).format('$0,0.00') }}
					</div>
					<div slot="ret_isr" slot-scope="record" class="text-right">
						{{ numeral(record.general_discount).format('$0,0.00') }}
					</div>
					<div slot="ret_iva" slot-scope="record" class="text-right">
						{{ numeral(record.general_discount).format('$0,0.00') }}
					</div>
					<div slot="total" slot-scope="record" class="text-right">
						{{ numeral(record.total).format('$0,0.00') }}
					</div>
					<div slot="status" slot-scope="record" class="text-right">
						{{ getODCStatus(record.status) }}
						<div v-if="record.ppd_payments">
							{{
								numeral(
									record.ppd_payments.reduce((accumulator, currentValue) => {
										if (!currentValue.paymentReceipt) {
											return accumulator + numeral(currentValue.total).value()
										}
										return accumulator + 0
									}, 0),
								).format('$0,0.00')
							}}
						</div>
					</div>
				</a-table>
			</div>
		</a-modal>
	</div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment-timezone'
import utilities from '@/services/utilities'
import attendanceRecord from '@/components/attendanceRecord'
import { cfdiUseList } from '@/constants'
//
const dateFormat = 'YYYY-MM-DD'
moment.tz.setDefault('America/Mexico_City')

export default {
	name: 'directionDashboard',
	components: {
		attendanceRecord,
		VueC3,
	},
	computed: {
		...mapGetters('direction', ['dashboardReport', 'getTechnicProfitReport', 'filters']),
		...mapGetters('user', ['user']),
		allowShowGraphic() {
			let allowedUserPermition = [1, 8, 11, 12, 13, 27]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		allowShowCash() {
			let allowedUserPermition = [1, 8, 11, 12, 13, 17, 27]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		totalIngresos() {
			return utilities.objectValidate(this.dashboardReport, 'pacific.in', 0) + utilities.objectValidate(this.dashboardReport, 'rls.in', 0)
		},
		totalEgresos() {
			return (
				utilities.objectValidate(this.dashboardReport, 'panel.out', 0) + utilities.objectValidate(this.dashboardReport, 'pacific.out', 0) + utilities.objectValidate(this.dashboardReport, 'rls.out', 0)
			)
		},
		barOptions() {
			return {
				data: {
					tooltip: {
						format: {
							title: function (x, index) {
								return 'Data ' + x
							},
						},
					},
					json: utilities.objectValidate(this.dashboardReport, 'groupData.labels', []).map((e, i) => {
						if (this.branch_office_id == '' || this.branch_office_id == 1) {
							return {
								name: e,
								Limpio: this.dashboardReport.groupData.series[1][i].value,
								Boutique: this.dashboardReport.groupData.series[5][i].value,
								Refacciones: this.dashboardReport.groupData.series[0][i].value,
								'Mano de Obra RLS': this.dashboardReport.groupData.series[2][i].value,
								'Mano de Obra Empresas': this.dashboardReport.groupData.series[7][i].value,
								Total: this.dashboardReport.groupData.series[4][i].value,
								Flotación: this.dashboardReport.groupData.series[3][i].value,
								Envíos: this.dashboardReport.groupData.series[6][i].value,
							}
						}
						if (this.branch_office_id == 2) {
							return {
								name: e,
								Envíos: this.dashboardReport.groupData.series[6][i].value,
								Boutique: this.dashboardReport.groupData.series[5][i].value,
								Refacciones: this.dashboardReport.groupData.series[0][i].value,
								'Mano de Obra RLS': this.dashboardReport.groupData.series[2][i].value,
								'Mano de Obra Empresas': this.dashboardReport.groupData.series[7][i].value,
								Total: this.dashboardReport.groupData.series[4][i].value,
								Flotación: this.dashboardReport.groupData.series[3][i].value,
							}
						}
					}),
					keys: {
						x: 'name',
						value:
							this.branch_office_id == '' || this.branch_office_id == 1
								? ['Limpio', 'Boutique', 'Refacciones', 'Mano de Obra RLS', 'Mano de Obra Empresas', 'Total', 'Flotación', 'Envíos']
								: ['Envíos', 'Boutique', 'Refacciones', 'Mano de Obra RLS', 'Mano de Obra Empresas', 'Total', 'Flotación'],
					},
					type: 'bar',
					types: {
						Total: 'spline',
						Flotación: 'spline',
					},
				},
				bar: {
					width: {
						max: 20,
					},
				},
				axis: {
					x: {
						type: 'category',
					},
					y: {
						show: !1,
					},
				},
				tooltip: {
					format: {
						value: function (value, ratio, id) {
							return numeral(value).format('$0,0.00')
						},
					},
				},
				grid: {
					y: {
						show: !0,
					},
					x: {
						show: !1,
					},
				},
				color: {
					// value: ['Limpio', 'Boutique', 'Refacciones', 'Mano de Obra RLS','Mano de Obra Empresas', 'Total', 'Promedio', 'Envíos'],
					pattern:
						this.branch_office_id == '' || this.branch_office_id == 1
							? ['#722ed1', '#42baf9', '#cff4fc', '#2b2f32', '#2b2f32', 'green', '#f5222e', '#fff3cd']
							: ['#fff3cd', '#42baf9', '#cff4fc', '#2b2f32', '#2b2f32', 'green', '#f5222e'],
				},
			}
		},
	},
	data() {
		return {
			activeTab: 'general', // general / available
			dateFormat,
			defaultDate: [moment().startOf('month').format(dateFormat), moment(moment().startOf('month').add(13, 'days')).format(dateFormat)],
			semester: moment().month() < 6 ? '1' : '2',
			year: moment().year(),
			segment: 'q',
			branch_office_id: '',
			//
			bar: new Vue(),
			colors: {
				primary: '#01a8fe',
				def: '#acb7bf',
				success: '#46be8a',
				danger: '#fb434a',
			},
			columns: [
				{
					title: '# de ODC',
					fixed: 'left',
					dataIndex: 'id',
					align: 'center',
					width: 100,
				},
				{
					title: 'Fecha de creación',
					scopedSlots: { customRender: 'created_at' },
					align: 'center',
					width: 130,
				},
				{
					title: 'Fecha de pago',
					scopedSlots: { customRender: 'payment_date' },
					align: 'center',
					width: 130,
				},
				{
					title: 'RFC proveedor',
					dataIndex: 'rfc',
					align: 'center',
					width: 150,
				},
				{
					title: 'Razón Social',
					scopedSlots: { customRender: 'business_name' },
					align: 'center',
					width: 250,
				},
				{
					title: 'Concepto',
					scopedSlots: { customRender: 'elements' },
					align: 'center',
					width: 500,
				},
				{
					title: 'Forma de pago',
					scopedSlots: { customRender: 'payment_method_data' },
					align: 'center',
					width: 150,
				},
				{
					title: 'Subtotal',
					scopedSlots: { customRender: 'subtotal' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Descuento',
					scopedSlots: { customRender: 'general_discount' },
					align: 'center',
					width: 100,
				},
				{
					title: 'IVA acreditable',
					scopedSlots: { customRender: 'iva' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Retención de ISR',
					scopedSlots: { customRender: 'ret_isr' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Retención de IVA',
					scopedSlots: { customRender: 'ret_iva' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Total',
					scopedSlots: { customRender: 'total' },
					align: 'center',
					width: 100,
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'status' },
					align: 'center',
					width: 100,
				},
			],
			loanPurchaseOrders: {
				visible: false,
				elements: [],
			},
		}
	},
	destroyed() {
		this.clearData()
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		moment,
		getProductivity() {
			this.$store.dispatch('direction/GET_PRODUCTIVITY').then((response) => {
				console.log('response-->', response)
			})
		},
		onCloseModal() {
			this.loanPurchaseOrders = {
				visible: false,
				elements: [],
			}
		},
		getIVA(id) {
			let theRecord = this.loanPurchaseOrders.elements.find((e) => e.id == id)
			if (utilities.objectValidate(theRecord, 'general_discount', false)) {
				if (theRecord.discount_type == 'after') {
					return numeral(((theRecord.total + theRecord.general_discount) / 1.16) * 0.16).format('0.00')
				} else {
					return numeral((theRecord.total / 1.16) * 0.16).format('0.00')
				}
			} else {
				return numeral((theRecord.total / 1.16) * 0.16).format('0.00')
			}
			return ''
		},
		getSubtotal(id) {
			let theRecord = this.loanPurchaseOrders.elements.find((e) => e.id == id)
			if (utilities.objectValidate(theRecord, 'general_discount', false)) {
				if (theRecord.discount_type == 'after') {
					return numeral((theRecord.total + theRecord.general_discount) / 1.16).format('0.00')
				} else {
					return numeral(theRecord.total / 1.16).format('0.00')
				}
			} else {
				return numeral(theRecord.total / 1.16).format('0.00')
			}
			return ''
		},
		getPaymentMethod(value) {
			if (value != '') {
				let paymentMethodData = JSON.parse(value)
				return numeral(paymentMethodData.payment_method).value() == 1 ? 'Efectivo' : 'Transferencia Bancaria'
			}
			return ''
		},
		getcfdiUse(code) {
			if (code) {
				return cfdiUseList.find((e) => e.key == code).label
			}
			return ''
		},
		getODCStatus(value) {
			switch (value) {
				case 'C':
					return 'Cotización'
				case 0:
					return 'Por autorizar'
				case 1:
					return 'Por pagar'
				case 1.1:
					return 'Por pagar'
				case 2:
					return 'Pagadas'
				case 3:
					return 'Facturadas'
				case 4:
					return 'Validadas'
				default:
					return ''
					break
			}
		},
		getLoanPurchaseOrders() {
			// console.log('getLoanPurchaseOrders');
			this.$store.dispatch('direction/GET_LOAN_PURCHASE_ORDERS').then((response) => {
				// console.log('response-->', response);
				this.loanPurchaseOrders = {
					elements: _.cloneDeep(response.data),
					visible: true,
				}
			})
		},
		clearData() {
			this.$store.commit('direction/SET_STATE', {
				dashboardReport: {
					semestralGoal: 0,
					average: 0,
					panel: {
						in: 0,
						out: 0,
					},
					pacific: {
						in: 0,
						out: 0,
					},
					rls: {
						in: 0,
						out: 0,
					},
					loans: {
						amount: 0,
						paymentNumber: 0,
						paymentTotal: 0,
					},
				},
			})
		},
		initModule() {
			this.clearData()
			this.$store.dispatch('direction/GET_DASHBOARD_REPORT', {
				year: this.year,
				semester: this.semester,
				segment: this.segment,
				branch_office_id: this.branch_office_id,
			})
		},
	},
	watch: {
		dashboardReport(newData) {
			if (utilities.objectValidate(newData, 'granTotalGroup', 0) > 0) {
				setTimeout(() => {
					this.bar.$emit('init', this.barOptions)
				}, 500)
			}
		},
	},
}
</script>
